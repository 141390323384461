import { NotFoundComponent } from 'components';
import { Layout } from 'components/layout';
import React from 'react';

const NotFoundPage: React.FC = () => (
  <Layout isGhostNav={false}>
    <NotFoundComponent />
  </Layout>
);
export default NotFoundPage;
